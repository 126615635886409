
export default {
  "pt-PT": {
    common: {
      subtotal: "O subtotal",
      cancel: "Cancelar",
      processing: "Processamento",
      pay: "pagar",
      error: "Algo correu mal, por favor tente novamente mais tarde!",
      code: "Código",
      status: "Estado",
      day: "dia | dias",
      week: "semana",
      month: "mês",
      year: "ano",
      minute: "minute",
      createdAt: "Criado Em",
      apply: "Aplicar",
      remove: "Remover",
      free: "Grátis",
      noPaymentMethod: "Não foi encontrada nenhuma forma de pagamento válida",
    },
    invoice: {
      INVOICE: "FATURA",
      contactDetailsSection: {
        billedTo: "Faturado para",
      },
      invoiceDetailsSection: {
        invoiceNo: "Número de fatura",
        issueDate: "Data de Emissão",
        dueDate: "Data de Vencimento",
      },
      invoicePayButton: {
        paid: "pago",
        pay: "pagar",
      },
      invoiceItemsList: {
        itemName: "Nome do item",
        price: "Preço",
        qty: "Quant.+",
      },
      orderSummary: {
        amountDue: "Valor devido",
        taxes: "Impostos",
        discount: "Desconto",
        shipping: "Transporte",
      },
      amountPaid: "Valor Pago",
      generatedOn: "Gerado em",
      generatingInvoicePDF: "Gerar fatura em formato PDF",
      generatingReceiptPDF: "Gerando comprovante em formato PDF",
      error: "OPS, A fatura que está procurando não pode ser encontrada!",
      receipt: {
        RECEIPT: "recibo",
        receiptNo: "Número de recibo",
        datePaid: "Data paga",
        error: "OPS, O recibo que procura não pode ser encontrado!",
      },
      download: {
        clickHere: "Clique aqui",
        toDownloadPDF: "para fazer o download do pdf",
      },
      clickHereToMakePaymentNow: "Clique aqui para efetuar o pagamento agora",
      paymentProcessing: "Processamento de pagamento",
      PAID: "pago",
      termsAndNotes: "Termos e Notas",
      total: "Total",
    },
    paymentLink: {
      error:
        "OPS, O link de pagamento que você está procurando não pode ser encontrado!",
      productDetails: "Detalhes do Produto",
      validation: {
        firstName: "Nome próprio obrigatório",
        lastName: "Apelido obrigatório",
        address: "Endereço obrigatório",
        city: "Cidade Obrigatória",
        state: "Obrigatório por lei",
        phoneRequried: "Número de telefone obrigatório",
        phoneChars: "O número de telefone só pode conter números",
        phoneCountryCode:
          "Por favor, certifique-se de que o seu número de telefone contém os caracteres do código do país antes de continuar",
        email: "Email obrigatório",
        validEmail: "Por favor, introduza um endereço de email válido",
      },
      additionalSetupFee: "Taxa única de configuração",
      afterTrialText: "Após o fim do seu período experimental, será cobrado(a)",
      afterTrialCancellationText: "Você sempre pode cancelar antes disso",
      subscriptionTextPrefix: "Ao confirmar a sua subscrição, está a permitir",
      subscriptionTextSuffix:
        "Para cobrar futuros pagamentos de acordo com os termos. Você sempre pode cancelar sua assinatura",
      firstName: "Primeiro Nome",
      lastName: "Último nome",
      email: "Email",
      phone: "Telefone",
      address: "Morada",
      addressLine1: "Linha de Endereço 1",
      addressLine2: "Linha de Endereço 2",
      city: "Cidade",
      state: "Estado",
      paymentSuccessful:
        "Pagamento recebido com sucesso! Obrigado por escolher nossos serviços. Sua transação está confirmada",
      thanksText: "Obrigado pelo seu pagamento",
      notification: {
        errorPayment: "Erro ao efetuar o pagamento",
        orderSuccessful: "Encomenda realizada com sucesso",
        invalidCoupon: "Código de cupão inválido",
      },
      per: "por",
      then: "Então",
      free: "Grátis",
      day: "dia",
      days: "dias",
      discount: "Desconto (cupão)",
      subtotal: "O subtotal",
      subtotalAfterDiscount: "Subtotal após desconto",
      taxes: "Impostos",
      priceAfterTrial:
        "{currency}{price} / {interval} após teste | {currency}{price} / {intervalCount} {interval} após teste",
    },
    paymentMethod: {
        update: "Atualização",
        upcomingInvoicePayment: "Próximo pagamento de fatura",
        updatePaymentMethod: "Atualizar método de pagamento",
        successTitle: "O método de pagamento foi atualizado com sucesso",
        successMessage: "O método de pagamento foi adicionado à subscrição",
        purchasedOn: "Adquirido em",
        on: "Em",
        endingIn: "terminando em"
    }
  },
};
