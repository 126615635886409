export default {
  de: {
    common: {
      subtotal: "Zwischensumme",
      cancel: "Abbrechen",
      processing: "Verarbeitung",
      pay: "Zahlen",
      error: "Etwas ist schiefgegangen, bitte versuche es später noch einmal!",
      code: "Code",
      status: "Status",
      day: "Tag | Tage",
      week: "Woche",
      month: "Monat",
      year: "Jahr",
      minute: "minute",
      createdAt: "Erstellt am",
      apply: "Bewerben",
      remove: "Entfernen",
      free: "frei",
      noPaymentMethod: "No valid payment method found",
    },
    invoice: {
      INVOICE: "Rechnung",
      contactDetailsSection: {
        billedTo: "In Rechnung gestellt",
      },
      invoiceDetailsSection: {
        invoiceNo: "Rechnungsnummer",
        issueDate: "Ausgabedatum",
        dueDate: "Fälligkeitsdatum",
      },
      invoicePayButton: {
        paid: "Bezahlt",
        pay: "Zahlen",
      },
      invoiceItemsList: {
        itemName: "Artikelname",
        price: "Preis",
        qty: "Menge",
      },
      orderSummary: {
        amountDue: "Betrag fällig",
        taxes: "Steuern",
        discount: "Rabatt",
        shipping: "Versand",
      },
      amountPaid: "Betrag bezahlt",
      generatedOn: "Generiert am",
      generatingInvoicePDF: "Erstellen der Rechnung als PDF",
      generatingReceiptPDF: "Erzeugung der Quittungs-PDF",
      error:
        "OOPS, Die Rechnung, nach der Sie suchen, konnte nicht gefunden werden!",
      receipt: {
        RECEIPT: "Quittung",
        receiptNo: "Quittungsnummer",
        datePaid: "Bezahlt am",
        error:
          "OOPS, Die Quittung, die Sie suchen, konnte nicht gefunden werden!",
      },
      download: {
        clickHere: "Klicke hier",
        toDownloadPDF: "um das PDF herunterzuladen",
      },
      clickHereToMakePaymentNow:
        "Klicke hier, um die Zahlung jetzt vorzunehmen",
      paymentProcessing: "Zahlungsabwicklung",
      PAID: "BEZAHLT",
      termsAndNotes: "Bedingungen & Anmerkungen",
      total: "Gesamt",
    },
    paymentLink: {
      error:
        "OOPS, Der Zahlungslink, den Sie suchen, konnte nicht gefunden werden!",
      productDetails: "Produktdetails",
      validation: {
        firstName: "Vorname erforderlich",
        lastName: "Nachname erforderlich",
        address: "Adresse erforderlich",
        city: "Stadt erforderlich",
        state: "gesetzlich vorgeschrieben",
        phoneRequried: "Telefonnummer erforderlich",
        phoneChars: "Telefonnummer darf nur Zahlen enthalten",
        phoneCountryCode:
          "Bitte stellen Sie sicher, dass Ihre Telefonnummer die Zeichen gemäß Ländervorwahl enthält, bevor Sie fortfahren",
        email: "E-Mail erforderlich",
        validEmail: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
      },
      additionalSetupFee: "Einrichtungsgebühr",
      afterTrialText:
        "Nach Ablauf Ihres Probezeitraums wird Ihnen eine Gebühr berechnet",
      afterTrialCancellationText: "Du kannst immer vorher stornieren",
      subscriptionTextPrefix:
        "Durch Bestätigung Ihres Abonnements gestatten Sie",
      subscriptionTextSuffix:
        "Ihnen zukünftige Zahlungen gemäß ihren Bedingungen in Rechnung stellen. Sie können Ihr Abonnement jederzeit kündigen",
      firstName: "Vorname",
      lastName: "Nachname",
      email: "E-Mail",
      phone: "Telefon",
      address: "Adresse",
      addressLine1: "Adresszeile 1",
      addressLine2: "Adresszeile 2",
      city: "Stadt",
      state: "Staat",
      paymentSuccessful:
        "Zahlung erfolgreich erhalten! Vielen Dank, dass Sie unsere Dienstleistungen gewählt haben. Ihre Transaktion ist bestätigt",
      thanksText: "Danke für Ihre Zahlung",
      notification: {
        errorPayment: "Fehler bei der Zahlungserstellung",
        orderSuccessful: "Bestellung erfolgreich aufgegeben",
        invalidCoupon: "Ungültiger Gutscheincode",
      },
      per: "pro oder je",
      then: "Dann",
      free: "frei",
      day: "Tag",
      days: "Tage",
      discount: "Rabatt (Gutschein)",
      subtotal: "Zwischensumme",
      subtotalAfterDiscount: "Zwischensumme nach Rabatt",
      taxes: "Steuern",
      priceAfterTrial:
        "{currency}{price} / {interval} nach Testversion | {currency}{price} / {intervalCount} {interval} nach Testversion",
    },
    paymentMethod: {
        update: "Aktualisierung",
        upcomingInvoicePayment: "Bevorstehende Rechnungszahlung",
        updatePaymentMethod: "Aktualisieren Zahlungsmethode",
        successTitle: "Zahlungsmethode wurde erfolgreich aktualisiert",
        successMessage: "Die Zahlungsmethode wurde dem Abonnement hinzugefügt",
        purchasedOn: "Gekauft am",
        on: "An:",
        endingIn: "endet in"
    }
  },
};
