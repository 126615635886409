export default {
  "en-US": {
    common: {
      subtotal: "Subtotal",
      cancel: "Cancel",
      processing: "Processing",
      pay: "Pay",
      error: "Something went wrong, Please try again later!",
      code: "Code",
      status: "Status",
      day: "day | days",
      week: "week",
      month: "month",
      year: "year",
      minute: "minute",
      createdAt: "Created At",
      apply: "Apply",
      remove: "Remove",
      free: "free",
      noPaymentMethod: 'No valid payment method found'
    },
    invoice: {
      INVOICE: "INVOICE",
      contactDetailsSection: {
        billedTo: "Billed to",
      },
      invoiceDetailsSection: {
        invoiceNo: "Invoice No",
        issueDate: "Issue Date",
        dueDate: "Due Date",
      },
      invoicePayButton: {
        paid: "Paid",
        pay: "Pay",
        processing: 'Payment processing'
      },
      invoiceItemsList: {
        itemName: "Item name",
        price: "Price",
        qty: "Qty",
      },
      orderSummary: {
        amountDue: "Amount Due",
        taxes: "Taxes",
        discount: "Discount",
        shipping: "Shipping"
      },
      amountPaid: "Amount Paid",
      generatedOn: "Generated on",
      generatingInvoicePDF: "Generating invoice pdf",
      generatingReceiptPDF: "Generating receipt pdf",
      error: "OOPS, The invoice you are looking for cannot be found!",
      receipt: {
        RECEIPT: "RECEIPT",
        receiptNo: "Receipt No",
        datePaid: "Date Paid",
        error: "OOPS, The receipt you are looking for cannot be found!"
      },
      download: {
        clickHere: "Click here",
        toDownloadPDF: "to download pdf",
      },
      clickHereToMakePaymentNow: "Click here to make the payment now",
      paymentProcessing: "Payment Processing",
      PAID: "PAID",
      termsAndNotes: "Terms & Notes",
      total: "Total",
    },
    paymentLink: {
      error: "OOPS, The payment link you are looking for cannot be found!",
      productDetails : "Product Details",
      validation: {
        firstName: 'First Name Required',
        lastName: 'Last Name Required',
        address: 'Address Required',
        city: 'City Required',
        state: 'State Required',
        phoneRequried: 'Phone Number Required',
        phoneChars: 'Phone number can only contain numbers',
        phoneCountryCode: 'Please ensure that your phone number contains characters as per country code before proceeding',
        email: 'Email Required',
        validEmail: 'Please enter a valid email address'
      },
      additionalSetupFee: "One time setup fee",
      afterTrialText: "After your trial ends, you will be charged",
      afterTrialCancellationText: "You can always cancel before then.",
      subscriptionTextPrefix: "By confirming your subscription, you allow",
      subscriptionTextSuffix: "to charge you for future payments in accordance with their terms. You can always cancel your subscription.",
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      phone: "Phone",
      address: "Address",
      addressLine1: "Address Line 1",
      addressLine2: "Address Line 2",
      city: "City",
      state: "State",
      paymentSuccessful: "Payment received successfully! Thank you for choosing our services. Your transaction is confirmed",
      thanksText: "Thanks for your payment",
      notification: {
        errorPayment: "Error in making payment",
        orderSuccessful: "Order placed successfully",
        invalidCoupon: "Invalid coupon code"
      },
      per: "per",
      then: "Then",
      free: "free",
      day: "day",
      days: "days",
      discount: "Discount (coupon)",
      subtotal: "Subtotal",
      subtotalAfterDiscount: "Subtotal after discount",
      taxes: "Taxes",
      priceAfterTrial: "{currency}{price} / {interval} after trial | {currency}{price} / {intervalCount} {interval} after trial"
    },
    paymentMethod: {
      update: "Update",
      upcomingInvoicePayment: "Upcoming invoice payment",
      updatePaymentMethod: "Update payment method",
      successTitle: "Payment method was successfully updated",
      successMessage: "The Payment method was added to the subscription",
      purchasedOn: "Purchased on",
      on: "On",
      endingIn: "ending in"
    }
  },
};
