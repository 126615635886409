export default {
  "fr-FR": {
    common: {
      subtotal: "Sous-total",
      cancel: "annuler",
      processing: "Traitement",
      pay: "Payer",
      error: "Quelque chose s'est mal passé, veuillez réessayer plus tard !",
      code: "code",
      status: "Statut",
      day: "le jour | les jours",
      week: "la semaine",
      month: "mois",
      year: "année",
      minute: "minute",
      createdAt: "Créé le",
      apply: "Appliquer",
      remove: "Retirer",
      free: "gratuit",
      noPaymentMethod: "Aucun moyen de paiement valide trouvé",
    },
    invoice: {
      INVOICE: "FACTURE",
      contactDetailsSection: {
        billedTo: "Facturé à",
      },
      invoiceDetailsSection: {
        invoiceNo: "Numéro de facture",
        issueDate: "Date de publication",
        dueDate: "Date d'échéance",
      },
      invoicePayButton: {
        paid: "payé",
        pay: "Payer",
      },
      invoiceItemsList: {
        itemName: "nom de l'article",
        price: "Prix",
        qty: "Quantité",
      },
      orderSummary: {
        amountDue: "Montant dû",
        taxes: "Les impôts",
        discount: "Réduction",
        shipping: "Expédition",
      },
      amountPaid: "Montant payé",
      generatedOn: "Généré le",
      generatingInvoicePDF: "Génération de facture PDF",
      generatingReceiptPDF: "Génération du reçu en format PDF",
      error: "OUPS, La facture que vous recherchez est introuvable !",
      receipt: {
        RECEIPT: "ticket",
        receiptNo: "Numéro de reçu",
        datePaid: "Date de paiement",
        error: "OUPS, le reçu que vous recherchez est introuvable !",
      },
      download: {
        clickHere: "Cliquez ici",
        toDownloadPDF: "télécharger le PDF",
      },
      clickHereToMakePaymentNow:
        "Cliquez ici pour effectuer le paiement maintenant",
      paymentProcessing: "Traitement des paiements",
      PAID: "payé",
      termsAndNotes: "Termes et notes",
      total: 'Le terme "Total" est traduit en français comme "Total',
    },
    paymentLink: {
      error: "Oops, le lien de paiement que vous recherchez est introuvable !",
      productDetails: "Détails du produit",
      validation: {
        firstName: "Prénom requis",
        lastName: "Nom de famille requis",
        address: "Adresse requise",
        city: "Ville obligatoire",
        state: "Obligation légale",
        phoneRequried: "Numéro de téléphone requis",
        phoneChars: "Le numéro de téléphone ne peut contenir que des chiffres",
        phoneCountryCode:
          "Veuillez vous assurer que votre numéro de téléphone contient les caractères du code pays avant de continuer",
        email: "E-mail obligatoire",
        validEmail: "Veuillez saisir une adresse e-mail valide",
      },
      additionalSetupFee: "Frais de configuration unique",
      afterTrialText: "Après la fin de votre essai, des frais seront facturés",
      afterTrialCancellationText:
        "Vous pouvez toujours annuler avant cette date",
      subscriptionTextPrefix: "En confirmant votre abonnement, vous autorisez",
      subscriptionTextSuffix:
        "vous facturer pour les paiements futurs conformément à leurs conditions. Vous pouvez toujours annuler votre abonnement",
      firstName: "Prénom",
      lastName: "Nom de famille",
      email: "courrier électronique",
      phone: "Téléphone",
      address: "L'adresse",
      addressLine1: "Ligne d'adresse 1",
      addressLine2: "Ligne d'adresse 2",
      city: "City",
      state: "État",
      paymentSuccessful:
        "Paiement reçu avec succès ! Merci d'avoir choisi nos services. Votre transaction est confirmée",
      thanksText: "Merci pour votre paiement",
      notification: {
        errorPayment: "Erreur lors du paiement",
        orderSuccessful: "Commande passée avec succès",
        invalidCoupon: "Code coupon invalide",
      },
      per: "pour",
      then: "Alors",
      free: "gratuit",
      day: "jour",
      days: "jours",
      discount: "Réduction (coupon)",
      subtotal: "Sous-total",
      subtotalAfterDiscount: "Sous-total après réduction",
      taxes: "Les impôts",
      priceAfterTrial:
        "{price}{currency} / {interval} après l'essai | {price}{currency} / {intervalCount} {interval} après l'essai",
    },
    paymentMethod: {
        update: "Mettre à jour",
        upcomingInvoicePayment: "Prochain paiement de facture",
        updatePaymentMethod: "Mettre à jour le moyen de paiement",
        successTitle: "Le mode de paiement a été mis à jour avec succès",
        successMessage: "Le mode de paiement a été ajouté à l'abonnement",
        purchasedOn: "Acheté le",
        on: "Sur",
        endingIn: "se terminant par"
    }
  },
};
