import { createI18n } from 'vue-i18n'
import enUS from "@/locales/lang/en-US";
import de from "@/locales/lang/de";
import frCA from "@/locales/lang/fr-CA";
import fr from "@/locales/lang/fr-FR";
import it from "@/locales/lang/it";
import nl from "@/locales/lang/nl";
import pt from "@/locales/lang/pt-PT";
import ptBR from "@/locales/lang/pt-BR";
import es from "@/locales/lang/es";
import sv from "@/locales/lang/sv";
import da from "@/locales/lang/da";
import fi from "@/locales/lang/fi";
import no from "@/locales/lang/no";


const i18n = createI18n({
  locale: localStorage.getItem('locale') || "en-US",
  globalInjection: true,
  legacy: false,
  runtimeOnly: true,
  messages: {
    ...enUS,
    ...de,
    ...frCA,
    ...fr,
    ...it,
    ...nl,
    ...pt,
    ...ptBR,
    ...es,
    ...sv,
    ...da,
    ...fi,
    ...no,
  },
});

export default i18n;