import '@gohighlevel/ghl-ui/dist/style.css'
import '@iconify/iconify'
import './assets/index.css'

import App from './App.vue'
import { createApp } from 'vue'
import { createMetaManager } from 'vue-meta'
import { createPinia } from 'pinia'
import i18n from './locales'
import mitt from 'mitt';
import router from './router/'

const app = createApp(App).use(createMetaManager())

app.use(createPinia())
app.use(i18n);

const emitter = mitt();
app.config.globalProperties.emitter = emitter;

app.use(router)
app.mount('#app')
