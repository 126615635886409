export default {
  no: {
    common: {
      subtotal: "Subtotal",
      cancel: "Avbryt",
      processing: "Behandling",
      pay: "betale",
      error: "Noe gikk galt, vennligst prøv igjen senere!",
      code: "Kode",
      status: "Status",
      day: "dag | dager",
      week: "uke",
      month: "måned",
      year: "år",
      minute: "minute",
      createdAt: "Opprettet den",
      apply: "Søk",
      remove: "Fjern",
      free: "gratis",
      noPaymentMethod: "Nessun metodo di pagamento valido trovato",
    },
    invoice: {
      INVOICE: "FAKTURA",
      contactDetailsSection: {
        billedTo: "Belastet til",
      },
      invoiceDetailsSection: {
        invoiceNo: "Fakturanummer",
        issueDate: "Utgivelsesdato",
        dueDate: "Forfallsdato",
      },
      invoicePayButton: {
        paid: "Betalt",
        pay: "betale",
      },
      invoiceItemsList: {
        itemName: "Varenavn",
        price: "Pris",
        qty: "Antall",
      },
      orderSummary: {
        amountDue: "Beløp som skyldes",
        taxes: "Skatter",
        discount: "Rabatt",
        shipping: "Frakt",
      },
      amountPaid: "Beløp betalt",
      generatedOn: "Generert den",
      generatingInvoicePDF: "Genererer fakturapdf",
      generatingReceiptPDF: "Genererer kvitterings-PDF",
      error: "OPS, Fakturaen du leter etter kan ikke bli funnet!",
      receipt: {
        RECEIPT: "kvittering",
        receiptNo: "Kvitteringsnummer",
        datePaid: "Dato betalt",
        error: "OPS, Kvitteringen du leter etter kan ikke bli funnet!",
      },
      download: {
        clickHere: "Klikk her",
        toDownloadPDF: "å laste ned pdf",
      },
      clickHereToMakePaymentNow: "Klikk her for å gjennomføre betalingen nå",
      paymentProcessing: "Betalingsbehandling",
      PAID: "betalt",
      termsAndNotes: "Vilkår og notater",
      total: "Total",
    },
    paymentLink: {
      error: "OPS, lenken til betalingen du leter etter kan ikke finnes!",
      productDetails: "Produktinformasjon",
      validation: {
        firstName: "Fornavn påkrevd",
        lastName: "Etternavn påkrevd",
        address: "Adresse nødvendig",
        city: "By påkrevd",
        state: "Statlig pålagt",
        phoneRequried: "Telefonnummer påkrevd",
        phoneChars: "Telefonnummer kan bare inneholde tall",
        phoneCountryCode:
          "Vennligst sørg for at telefonnummeret ditt inneholder tegn i samsvar med landskoden før du fortsetter",
        email: "E-post påkrevd",
        validEmail: "Vennligst skriv inn en gyldig e-postadresse",
      },
      additionalSetupFee: "Engangsoppsettavgift",
      afterTrialText: "Etter at prøveperioden er over, vil du bli belastet",
      afterTrialCancellationText: "Du kan alltid avbestille før det",
      subscriptionTextPrefix: "Ved å bekrefte abonnementet ditt, tillater du",
      subscriptionTextSuffix:
        "å belaste deg for fremtidige betalinger i samsvar med deres vilkår. Du kan alltid avbryte abonnementet ditt",
      firstName: "Fornavn",
      lastName: "Etternavn",
      email: "E-post",
      phone: "Telefon",
      address: "Adresse",
      addressLine1: "Adresse linje 1",
      addressLine2: "Adresse linje 2",
      city: "By",
      state: "stat",
      paymentSuccessful:
        "Betaling mottatt. Takk for at du valgte våre tjenester. Din transaksjon er bekreftet",
      thanksText: "Takk for betalingen din",
      notification: {
        errorPayment: "Feil ved gjennomføring av betaling",
        orderSuccessful: "Bestillingen er plassert vellykket",
        invalidCoupon: "Ugyldig kupongkode",
      },
      per: "per - per",
      then: "Da",
      free: "gratis",
      day: "dag",
      days: "dager",
      discount: "Rabatt (kupong)",
      subtotal: "Subtotal",
      subtotalAfterDiscount: "Delsum etter rabatt",
      taxes: "Skatter",
      priceAfterTrial:
        "{currency}{price} / {interval} etter prøveperioden | {currency}{price} / {intervalCount} {interval} etter prøveperioden",
    },
    paymentMethod: {
        update: "Oppdater",
        upcomingInvoicePayment: "Kommende fakturabetaling",
        updatePaymentMethod: "Oppdater betalingsmetoden",
        successTitle: "Betalingsmetoden ble oppdatert vellykket",
        successMessage: "Betalingsmetoden ble lagt til abonnementet",
        purchasedOn: "Kjøpt den",
        on: "På",
        endingIn: "slutter om"
    }
  },
};
