export default {
  sv: {
    common: {
      subtotal: "Delsumma",
      cancel: "Avboka",
      processing: "Bearbetning",
      pay: "betala",
      error: "Något gick fel, Var god försök igen senare!",
      code: "Kod",
      status: "Status",
      day: "dag | dagar",
      week: "vecka",
      month: "månad",
      year: "år",
      minute: "minute",
      createdAt: "Skapad vid",
      apply: "Ansök",
      remove: "Ta bort",
      free: "fri",
      noPaymentMethod: "Ingen giltig betalningsmetod hittades",
    },
    invoice: {
      INVOICE: "FAKTURA",
      contactDetailsSection: {
        billedTo: "Fakturerad till",
      },
      invoiceDetailsSection: {
        invoiceNo: "Fakturanummer",
        issueDate: "Utgivningsdatum",
        dueDate: "Förfallodatum",
      },
      invoicePayButton: {
        paid: "Betalat",
        pay: "betala",
      },
      invoiceItemsList: {
        itemName: "Objektnamn",
        price: "Pris",
        qty: "Antal",
      },
      orderSummary: {
        amountDue: "Belopp att betala",
        taxes: "Skatter",
        discount: "Rabatt",
        shipping: "Frakt",
      },
      amountPaid: "Belopp betalat",
      generatedOn: "Genererad den",
      generatingInvoicePDF: "Genererar faktura i pdf-format",
      generatingReceiptPDF: "Genererar kvitton i PDF-format",
      error: "OJDÅ, Fakturan du letar efter kan inte hittas!",
      receipt: {
        RECEIPT: "kvitto",
        receiptNo: "Kvittonummer",
        datePaid: "Betaldatum",
        error: "OPS, Kvittot du letar efter kan inte hittas!",
      },
      download: {
        clickHere: "Klicka här",
        toDownloadPDF: "att ladda ner en pdf",
      },
      clickHereToMakePaymentNow: "Klicka här för att betala nu",
      paymentProcessing: "Betalningshantering",
      PAID: "betald",
      termsAndNotes: "Villkor och anteckningar",
      total: "Hel ",
    },
    paymentLink: {
      error: "OJ, Den betalningslänk du letar efter kan inte hittas!",
      productDetails: "Produktinformation",
      validation: {
        firstName: "Förnamn obligatoriskt",
        lastName: "Efternamn krävs",
        address: "Adress krävs",
        city: "Stad krävs",
        state: "Statligt krav",
        phoneRequried: "Telefonnummer krävs",
        phoneChars: "Telefonnumret kan endast innehålla siffror",
        phoneCountryCode:
          "Var god se till att ditt telefonnummer innehåller tecken enligt landskoden innan du fortsätter",
        email: "E-post krävs",
        validEmail: "Vänligen ange en giltig e-postadress",
      },
      additionalSetupFee: "Engångsavgift",
      afterTrialText:
        "Efter att din provperiod är slut kommer du att debiteras",
      afterTrialCancellationText: "Du kan alltid avboka innan dess",
      subscriptionTextPrefix:
        "Genom att bekräfta din prenumeration godkänner du",
      subscriptionTextSuffix:
        "att debitera dig för framtida betalningar enligt deras villkor. Du kan alltid avbryta din prenumeration",
      firstName: "Förnamn",
      lastName: "Efternamn",
      email: "E-post",
      phone: "Telefon",
      address: "Adress",
      addressLine1: "Adressrad 1",
      addressLine2: "Adressrad 2",
      city: "Stad",
      state: "Stat",
      paymentSuccessful:
        "Betalning mottagen framgångsrikt! Tack för att du valde våra tjänster. Din transaktion är bekräftad",
      thanksText: "Tack för din betalning",
      notification: {
        errorPayment: "Fel vid betalning",
        orderSuccessful: "Order placerad framgångsrikt",
        invalidCoupon: "Ogiltig kupongkod",
      },
      per: "per",
      then: "Då",
      free: "fri",
      day: "dag",
      days: "dagar",
      discount: "Rabatt (kupong)",
      subtotal: "Delsumma",
      subtotalAfterDiscount: "Delsumma efter rabatt",
      taxes: "Skatter",
      priceAfterTrial:
        "{currency}{price} / {interval} efter testet | {currency}{price} / {intervalCount} {interval} efter testet",
    },
    paymentMethod: {
        update: "Uppdatering",
        upcomingInvoicePayment: "Kommande fakturabetalning",
        updatePaymentMethod: "Uppdatera betalningsmetod",
        successTitle: "Betalningsmetoden har uppdaterats framgångsrikt",
        successMessage: "Betalningsmetoden lades till prenumerationen",
        purchasedOn: "Inköpt på",
        on: "På",
        endingIn: "Slutar i"
    }
  },
};
