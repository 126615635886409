export default {
  es: {
    common: {
      subtotal: "Subtotal",
      cancel: "Cancelar",
      processing: "Procesando",
      pay: "Pagar",
      error: "¡Algo salió mal, por favor inténtelo de nuevo más tarde!",
      code: "Código",
      status: "Estado",
      day: "día | días",
      week: "semana",
      month: "mes",
      year: "año",
      minute: "minute",
      createdAt: "Creado en",
      apply: "Aplicar",
      remove: "Eliminar",
      free: "gratis",
      noPaymentMethod: "No se encontró método de pago válido",
    },
    invoice: {
      INVOICE: "FACTURA",
      contactDetailsSection: {
        billedTo: "Cargado a",
      },
      invoiceDetailsSection: {
        invoiceNo: "Número de factura",
        issueDate: "Fecha de emisión",
        dueDate: "Fecha de vencimiento",
      },
      invoicePayButton: {
        paid: "Pagado",
        pay: "Pagar",
      },
      invoiceItemsList: {
        itemName: "Nombre del artículo",
        price: "Precio",
        qty: "Cantidad",
      },
      orderSummary: {
        amountDue: "Monto debido",
        taxes: "Impuestos",
        discount: "Descuento",
        shipping: "Envío",
      },
      amountPaid: "Cantidad Pagada",
      generatedOn: "Generado el",
      generatingInvoicePDF: "Generando factura en formato PDF",
      generatingReceiptPDF: "Generando archivo pdf del recibo",
      error: "¡UPS! No se puede encontrar la factura que estás buscando",
      receipt: {
        RECEIPT: "RECIBO",
        receiptNo: "Número de recibo",
        datePaid: "Fecha de pago",
        error: "¡UPS! ¡No se puede encontrar el recibo que estás buscando!",
      },
      download: {
        clickHere: "Haga clic aquí",
        toDownloadPDF: "descargar pdf",
      },
      clickHereToMakePaymentNow: "Haz clic aquí para realizar el pago ahora",
      paymentProcessing: "Procesamiento de pagos",
      PAID: "PAGADO",
      termsAndNotes: "Términos y Notas",
      total: "Total",
    },
    paymentLink: {
      error:
        "¡UPS! ¡No se puede encontrar el enlace de pago que estás buscando!",
      productDetails: "Detalles del producto",
      validation: {
        firstName: "Primer Nombre Requerido",
        lastName: "Apellido requerido",
        address: "Se requiere dirección",
        city: "Ciudad Requerida",
        state: "Obligatorio por ley",
        phoneRequried: "Número de teléfono requerido",
        phoneChars: "El número de teléfono solo puede contener números",
        phoneCountryCode:
          "Por favor asegúrese de que su número de teléfono contenga los caracteres del código de país antes de continuar",
        email: "Correo electrónico requerido",
        validEmail: "Ingrese una dirección de correo electrónico válida",
      },
      additionalSetupFee: "Tarifa de configuración única",
      afterTrialText: "Después de que finalice tu prueba, se te cobrará",
      afterTrialCancellationText: "Siempre puedes cancelar antes",
      subscriptionTextPrefix: "Al confirmar tu suscripción, permites",
      subscriptionTextSuffix:
        "cargarte por pagos futuros de acuerdo a sus términos. Siempre puedes cancelar tu suscripción",
      firstName: "Nombre de pila",
      lastName: "Apellido",
      email: "Correo electrónico",
      phone: "teléfono",
      address: "Dirección",
      addressLine1: "Línea de dirección 1",
      addressLine2: "Línea de dirección 2",
      city: "Ciudad",
      state: "Estado",
      paymentSuccessful:
        "¡Pago recibido con éxito! ¡Gracias por elegir nuestros servicios. Su transacción está confirmada",
      thanksText: "Gracias por su pago",
      notification: {
        errorPayment: "Error al realizar el pago",
        orderSuccessful: "Pedido realizado con éxito",
        invalidCoupon: "Código de cupón inválido",
      },
      per: "por",
      then: "Entonces",
      free: "gratis",
      day: "día",
      days: "días",
      discount: "Descuento (cupón)",
      subtotal: "Subtotal",
      subtotalAfterDiscount: "Subtotal después del descuento",
      taxes: "Impuestos",
      priceAfterTrial:
        "{currency}{price} / {interval} después del período de prueba | {currency}{price} / {intervalCount} {interval} después del período de prueba",
    },
    paymentMethod: {
        update: "Actualizar",
        upcomingInvoicePayment: "Pago de factura próximo",
        updatePaymentMethod: "Actualizar método de pago",
        successTitle: "El método de pago se actualizó correctamente",
        successMessage: "El método de pago se agregó a la suscripción",
        purchasedOn: "Comprado el",
        on: "En",
        endingIn: "terminando en"
    }
  },
};
