export default {
  fi: {
    common: {
      subtotal: "Välisumma",
      cancel: "peruuttaa",
      processing: "Käsittely",
      pay: "Maksaa",
      error: "Jotain meni pieleen, yritä uudelleen myöhemmin!",
      code: "Koodi",
      status: "Tila",
      day: "päivä | päivät",
      week: "viikko",
      month: "kuukausi",
      year: "vuosi",
      minute: "minute",
      createdAt: "Luotu\u001c",
      apply: "Hae",
      remove: "Poista",
      free: "ilmainen",
      noPaymentMethod: "Ei löytynyt kelvollista maksutapaa",
    },
    invoice: {
      INVOICE: "LASKU",
      contactDetailsSection: {
        billedTo: "Laskutettu",
      },
      invoiceDetailsSection: {
        invoiceNo: "Laskun numero",
        issueDate: "Julkaisupäivä",
        dueDate: "Eräpäivä",
      },
      invoicePayButton: {
        paid: "Maksettu",
        pay: "Maksaa",
      },
      invoiceItemsList: {
        itemName: "Tuotteen nimi",
        price: "Hinta",
        qty: "Kpl",
      },
      orderSummary: {
        amountDue: "Maksettava summa",
        taxes: "Verot",
        discount: "alennus",
        shipping: "Toimitus",
      },
      amountPaid: "Maksettava summa",
      generatedOn: "Generoitu",
      generatingInvoicePDF: "Luodaan laskun PDF",
      generatingReceiptPDF: "Luodaan kuittia pdf-muodossa",
      error: "OHO, Etsimääsi laskua ei löydy!",
      receipt: {
        RECEIPT: "kuitti",
        receiptNo: "Kuittinumero",
        datePaid: "Maksupäivä",
        error: "Voi ei, hakemaasi kuittia ei löytynyt!",
      },
      download: {
        clickHere: "Napsauta tästä",
        toDownloadPDF: "ladata PDF",
      },
      clickHereToMakePaymentNow: "Napsauta tästä suorittaaksesi maksun nyt",
      paymentProcessing: "Maksunkäsittely",
      PAID: "MAKSETTU",
      termsAndNotes: "Käsitteet ja huomautukset",
      total: "Kokonais-",
    },
    paymentLink: {
      error: "OHO, Etsimääsi maksulinkkiä ei löytynyt!",
      productDetails: "Tuotetiedot",
      validation: {
        firstName: "Etunimi vaaditaan",
        lastName: "Sukunimi vaaditaan",
        address: "Osoite vaaditaan",
        city: "Kaupunki vaaditaan",
        state: "Vaadittu valtion viranomaisten",
        phoneRequried: "Puhelinnumero vaaditaan",
        phoneChars: "Puhelinnumero voi sisältää vain numeroita",
        phoneCountryCode:
          "Varmista, että puhelinnumerosi sisältää maatunnuksen mukaiset merkit ennen jatkamista",
        email: "Sähköposti vaaditaan",
        validEmail: "Ole hyvä ja syötä kelvollinen sähköpostiosoite",
      },
      additionalSetupFee: "Yksi kerta-asetusmaksu",
      afterTrialText: "Kokeilujakson päätyttyä sinulta veloitetaan",
      afterTrialCancellationText: "Voit aina peruuttaa ennen sitä",
      subscriptionTextPrefix: "Vahvistamalla tilauksesi, sallit",
      subscriptionTextSuffix:
        "laskuttaa sinua tulevista maksuista heidän ehtojensa mukaisesti. Voit aina peruuttaa tilauksesi",
      firstName: "Etunimi",
      lastName: "Sukunimi",
      email: "Sähköposti",
      phone: "Puhelin",
      address: "Osoite",
      addressLine1: "Osoiterivi 1",
      addressLine2: "Osoiterivi 2",
      city: "Kaupunki",
      state: "Valtio",
      paymentSuccessful:
        "Maksu vastaanotettu onnistuneesti! Kiitos, kun valitsitte palvelumme. Ostoksenne on vahvistettu",
      thanksText: "Kiitos maksustasi",
      notification: {
        errorPayment: "Virhe maksun suorittamisessa",
        orderSuccessful: "Tilaus vastaanotettu onnistuneesti",
        invalidCoupon: "Virheellinen kuponkikoodi",
      },
      per: "kohden",
      then: "Sitten",
      free: "ilmainen",
      day: "päivä",
      days: "päivät",
      discount: "Alennus (kuponki)",
      subtotal: "Välisumma",
      subtotalAfterDiscount: "Välisumma alennuksen jälkeen",
      taxes: "Verot",
      priceAfterTrial:
        "{currency}{price} / {interval} kokeilun jälkeen | {currency}{price} / {intervalCount} {interval} kokeilun jälkeen",
    },
    paymentMethod: {
        update: "Päivitys",
        upcomingInvoicePayment: "Seuraavan laskun maksu",
        updatePaymentMethod: "Päivitä maksutapa",
        successTitle: "Maksutapa päivitettiin onnistuneesti",
        successMessage: "Maksutapa lisättiin tilaukseen",
        purchasedOn: "Ostettu päivämäärä",
        on: "Päällä",
        endingIn: "päättyen"
    }
  },
};
