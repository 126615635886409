export default {
  "pt-BR": {
    common: {
      subtotal: "Subtotal",
      cancel: "Cancelar",
      processing: "Processamento",
      pay: "Pagar",
      error: "Algo deu errado, por favor tente novamente mais tarde!",
      code: "Código",
      status: "Status",
      day: "dia | dias",
      week: "semana",
      month: "mês",
      year: "ano",
      minute: "minute",
      createdAt: "Criado em",
      apply: "Aplicar",
      remove: "Remover",
      free: "gratuito",
      noPaymentMethod: "Nenhum método de pagamento válido encontrado",
    },
    invoice: {
      INVOICE: "FATURA",
      contactDetailsSection: {
        billedTo: "Faturado para",
      },
      invoiceDetailsSection: {
        invoiceNo: "Número da Fatura",
        issueDate: "Data de emissão",
        dueDate: "Data de vencimento",
      },
      invoicePayButton: {
        paid: "Pago",
        pay: "Pagar",
      },
      invoiceItemsList: {
        itemName: "Nome do item",
        price: "Preço",
        qty: "Quantidade",
      },
      orderSummary: {
        amountDue: "Valor devido",
        taxes: "Impostos",
        discount: "Desconto",
        shipping: "Envio",
      },
      amountPaid: "Valor Pago",
      generatedOn: "Gerado em",
      generatingInvoicePDF: "Gerando fatura em PDF",
      generatingReceiptPDF: "Gerando recibo em PDF",
      error: "OPA, A fatura que você está procurando não pode ser encontrada!",
      receipt: {
        RECEIPT: "recibo",
        receiptNo: "Número do recibo",
        datePaid: "Data de pagamento",
        error:
          "OPS, O recibo que você está procurando não pode ser encontrado!",
      },
      download: {
        clickHere: "Clique aqui",
        toDownloadPDF: "Baixar PDF",
      },
      clickHereToMakePaymentNow: "Clique aqui para efetuar o pagamento agora",
      paymentProcessing: "Processamento de Pagamento",
      PAID: "PAGO",
      termsAndNotes: "Termos e Notas",
      total: "Total",
    },
    paymentLink: {
      error:
        "OPS, o link de pagamento que você está procurando não pode ser encontrado!",
      productDetails: "Detalhes do Produto",
      validation: {
        firstName: "Nome completo obrigatório",
        lastName: "Sobrenome obrigatório",
        address: "Endereço Requerido",
        city: "Cidade Obrigatória",
        state: "Obrigatório por lei",
        phoneRequried: "Número de telefone obrigatório",
        phoneChars: "Número de telefone só pode conter números",
        phoneCountryCode:
          "Por favor, certifique-se de que seu número de telefone contenha os caracteres de acordo com o código do país antes de prosseguir",
        email: "E-mail obrigatório",
        validEmail: "Por favor, insira um endereço de e-mail válido",
      },
      additionalSetupFee: "Taxa de configuração única",
      afterTrialText:
        "Após o término do seu período de teste, você será cobrado",
      afterTrialCancellationText: "Você sempre pode cancelar antes disso",
      subscriptionTextPrefix: "Ao confirmar a sua inscrição, você permite",
      subscriptionTextSuffix:
        "cobrá-lo por futuros pagamentos de acordo com seus termos. Você sempre pode cancelar sua assinatura",
      firstName: "Primeiro Nome",
      lastName: "Sobrenome",
      email: "Email",
      phone: "Telefone",
      address: "Endereço",
      addressLine1: "Endereço linha 1",
      addressLine2: "Endereço complementar 2",
      city: "Cidade",
      state: "Estado",
      paymentSuccessful:
        "Pagamento recebido com sucesso! Obrigado por escolher nossos serviços. Sua transação está confirmada",
      thanksText: "Obrigado pelo seu pagamento",
      notification: {
        errorPayment: "Erro ao realizar o pagamento",
        orderSuccessful: "Pedido realizado com sucesso",
        invalidCoupon: "Código de cupom inválido",
      },
      per: "por",
      then: "Então",
      free: "gratuito",
      day: "dia",
      days: "dias",
      discount: "Desconto (cupom)",
      subtotal: "Subtotal",
      subtotalAfterDiscount: "Subtotal após desconto",
      taxes: "Impostos",
      priceAfterTrial:
        "{currency}{price} / {interval} após o teste | {currency}{price} / {intervalCount} {interval} após o teste",
    },
    paymentMethod: {
        update: "Atualização",
        upcomingInvoicePayment: "Próximo pagamento de fatura",
        updatePaymentMethod: "Atualizar método de pagamento",
        successTitle: "Método de pagamento foi atualizado com sucesso",
        successMessage: "O método de pagamento foi adicionado à assinatura",
        purchasedOn: "Comprado em",
        on: "Em",
        endingIn: "terminando em"
    }
  },
};
