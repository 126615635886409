export default {
  it: {
    common: {
      subtotal: "Subtotale",
      cancel: "Cancellare",
      processing: "Elaborazione",
      pay: "Pagare",
      error: "Qualcosa è andato storto. Si prega di riprovare più tardi!",
      code: "Codice",
      status: "Stato",
      day: "giorno | giorni",
      week: "settimana",
      month: "mese",
      year: "anno",
      minute: "minute",
      createdAt: "Creato il",
      apply: "Applicare",
      remove: "Rimuovere",
      free: "gratuito",
      noPaymentMethod: "Nessun metodo di pagamento valido trovato",
    },
    invoice: {
      INVOICE: "FATTURA",
      contactDetailsSection: {
        billedTo: "Fatturato a",
      },
      invoiceDetailsSection: {
        invoiceNo: "Numero fattura",
        issueDate: "Data di emissione",
        dueDate: "Data di scadenza",
      },
      invoicePayButton: {
        paid: "Pagato",
        pay: "Pagare",
      },
      invoiceItemsList: {
        itemName: "Nome dell'oggetto",
        price: "Prezzo",
        qty: "Quantità",
      },
      orderSummary: {
        amountDue: "Importo dovuto",
        taxes: "Tasse",
        discount: "sconto",
        shipping: "Spedizione",
      },
      amountPaid: "Importo pagato",
      generatedOn: "Generato il",
      generatingInvoicePDF: "Generazione del PDF della fattura",
      generatingReceiptPDF: "Generazione del documento PDF di ricevuta",
      error: "OPS, la fattura che stai cercando non può essere trovata!",
      receipt: {
        RECEIPT: "ricevuta",
        receiptNo: "Ricevuta n",
        datePaid: "Data di pagamento",
        error: "OPS, Lo scontrino che stai cercando non può essere trovato!",
      },
      download: {
        clickHere: "Clicca qui",
        toDownloadPDF: "scaricare il pdf",
      },
      clickHereToMakePaymentNow: "Clicca qui per effettuare il pagamento ora",
      paymentProcessing: "Elaborazione dei pagamenti",
      PAID: "PAGATO",
      termsAndNotes: "Termini e Note",
      total: "Totale",
    },
    paymentLink: {
      error:
        "OPS, Il link di pagamento che stai cercando non può essere trovato!",
      productDetails: "Dettagli del prodotto",
      validation: {
        firstName: "Nome richiesto",
        lastName: "Cognome richiesto",
        address: "Indirizzo richiesto",
        city: "Città Obbligatoria",
        state: "Obbligatorio dallo Stato",
        phoneRequried: "Numero di telefono richiesto",
        phoneChars: "Il numero di telefono può contenere solo numeri",
        phoneCountryCode:
          "Si prega di assicurarsi che il proprio numero di telefono contenga i caratteri del codice del paese prima di procedere",
        email: "Email richiesta",
        validEmail: "Inserire un indirizzo email valido",
      },
      additionalSetupFee: "Tariffa di attivazione una tantum",
      afterTrialText:
        "Dopo la fine del tuo periodo di prova, ti sarà addebitato un costo",
      afterTrialCancellationText: "Puoi sempre annullare prima di allora",
      subscriptionTextPrefix: "Confermando la tua iscrizione, acconsenti",
      subscriptionTextSuffix:
        "addebitarti per i pagamenti futuri in conformità con le loro condizioni. Puoi sempre annullare la tua iscrizione",
      firstName: "Nome-",
      lastName: "Cognome",
      email: "Email",
      phone: "Telefono",
      address: "Indirizzo",
      addressLine1: "Indirizzo riga 1",
      addressLine2: "Indirizzo riga 2",
      city: "Città",
      state: "Stato",
      paymentSuccessful:
        "Pagamento ricevuto con successo! Grazie per aver scelto i nostri servizi. La transazione è confermata",
      thanksText: "Grazie per il tuo pagamento",
      notification: {
        errorPayment: "Errore nel effettuare il pagamento",
        orderSuccessful: "Ordine effettuato con successo",
        invalidCoupon: "Codice coupon non valido",
      },
      per: "per",
      then: "Allora",
      free: "gratuito",
      day: "giorno",
      days: "giorni",
      discount: "Sconto (coupon)",
      subtotal: "Subtotale",
      subtotalAfterDiscount: "Subtotale dopo lo sconto",
      taxes: "Tasse",
      priceAfterTrial:
        "{currency}{price} / {interval} dopo il periodo di prova | {currency}{price} / {intervalCount} {interval} dopo il periodo di prova",
    },
    paymentMethod: {
        update: "Aggiornamento",
        upcomingInvoicePayment: "Prossimo pagamento della fattura",
        updatePaymentMethod: "Aggiorna metodo di pagamento",
        successTitle: "Il metodo di pagamento è stato aggiornato con successo",
        successMessage: "Il metodo di pagamento è stato aggiunto all'abbonamento",
        purchasedOn: "Acquistato il",
        on: "Sul",
        endingIn: "che termina in"
    }
  },
};
