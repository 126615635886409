export default {
  "fr-CA": {
    common: {
      subtotal: "Sous-total",
      cancel: "Annuler",
      processing: "Traitement",
      pay: "Payer",
      error: "Quelque chose s'est mal passé, veuillez réessayer plus tard !",
      code: "Code",
      status: "Status",
      day: "jour | jours",
      week: "semaine",
      month: "mois",
      year: "année",
      minute: "minute",
      createdAt: "Créé à",
      apply: "Appliquer",
      remove: "Retirer",
      free: "gratuit",
      noPaymentMethod: "No valid payment method found",
    },
    invoice: {
      INVOICE: "FACTURE",
      contactDetailsSection: {
        billedTo: "Facturé à",
      },
      invoiceDetailsSection: {
        invoiceNo: "Numéro de facture",
        issueDate: "Date d'émission",
        dueDate: "Date d'échéance",
      },
      invoicePayButton: {
        paid: "Payé",
        pay: "Payer",
      },
      invoiceItemsList: {
        itemName: "Nom de l'article",
        price: "Prix",
        qty: "Quantité",
      },
      orderSummary: {
        amountDue: "Montant dû",
        taxes: "Impôts",
        discount: "Rabais",
        shipping: "Expédition",
      },
      amountPaid: "Montant Payé",
      generatedOn: "Généré le",
      generatingInvoicePDF: "Génération de facture en format PDF",
      generatingReceiptPDF: "Génération du reçu en format PDF",
      error: "OUPS, La facture que vous cherchez ne peut être trouvée !",
      receipt: {
        RECEIPT: "reçu",
        receiptNo: "Numéro de reçu",
        datePaid: "Date payée",
        error: "OUPS, Le reçu que vous recherchez ne peut être trouvé !",
      },
      download: {
        clickHere: "Cliquez ici",
        toDownloadPDF: "télécharger un fichier PDF",
      },
      clickHereToMakePaymentNow:
        "Cliquez ici pour effectuer le paiement maintenant",
      paymentProcessing: "Traitement des paiements",
      PAID: "RÉGLÉ",
      termsAndNotes: "Termes et notes",
      total: "Total",
    },
    paymentLink: {
      error:
        "OUPS, Le lien de paiement que vous recherchez ne peut être trouvé !",
      productDetails: "Détails du produit",
      validation: {
        firstName: "Prénom requis",
        lastName: "Nom de famille requis",
        address: "Adresse requise",
        city: "Ville Requise",
        state: "Province / États / Térritoire requis",
        phoneRequried: "Numéro de téléphone requis",
        phoneChars: "Le numéro de téléphone ne peut contenir que des chiffres",
        phoneCountryCode:
          "Veuillez vous assurer que votre numéro de téléphone contient des caractères conformes au code du pays avant de continuer",
        email: "Courriel requis",
        validEmail: "Veuillez entrer une adresse courriel valide",
      },
      additionalSetupFee: "Frais de configuration unique",
      afterTrialText: "Après la fin de votre essai, vous serez facturé",
      afterTrialCancellationText: "Vous pouvez toujours annuler avant cela",
      subscriptionTextPrefix:
        "En confirmant votre abonnement, vous nous autorisez",
      subscriptionTextSuffix:
        "vous facturer pour les paiements futurs conformément à leurs conditions. Vous pouvez toujours annuler votre abonnement",
      firstName: "Prénom",
      lastName: "Nom de famille",
      email: "Courriel",
      phone: "Téléphone",
      address: "Adresse",
      addressLine1: "Ligne d'adresse 1",
      addressLine2: "Ligne d'adresse 2",
      city: "Ville",
      state: "État",
      paymentSuccessful:
        "Paiement reçu avec succès ! Merci d'avoir choisi nos services. Votre transaction est confirmée",
      thanksText: "Merci pour votre paiement",
      notification: {
        errorPayment: "Erreur lors du paiement",
        orderSuccessful: "Commande bien reçue",
        invalidCoupon: "Code de coupon invalide",
      },
      per: "par",
      then: "Alors",
      free: "gratuit",
      day: "jour",
      days: "jours",
      discount: "Rabais (coupon)",
      subtotal: "Sous-total",
      subtotalAfterDiscount: "Sous-total après rabais",
      taxes: "Impôts",
      priceAfterTrial:
        "{currency}{price} / {interval} après l'essai | {currency}{price} / {intervalCount} {interval} après l'essai",
    },
    paymentMethod: {
        update: "Mettre à jour",
        upcomingInvoicePayment: "Prochain paiement de facture",
        updatePaymentMethod: "Mettre à jour le mode de paiement",
        successTitle: "La méthode de paiement a été mise à jour avec succès",
        successMessage: "La méthode de paiement a été ajoutée à l'abonnement",
        purchasedOn: "Acheté le",
        on: "Sur",
        endingIn: "se terminant en"
    }
  },
};
