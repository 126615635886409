export default {
  da: {
    common: {
      subtotal: "Delsum",
      cancel: "aflyse",
      processing: "Behandling",
      pay: "Betale",
      error: "Noget gik galt, Prøv venligst igen senere!",
      code: "Kode",
      status: "Status",
      day: "dag | dage",
      week: "uge",
      month: "måned",
      year: "år",
      minute: "minute",
      createdAt: "Oprettet den",
      apply: "Ansøg",
      remove: "Fjern",
      free: "gratis",
      noPaymentMethod: "Ingen gyldig betalingsmetode fundet",
    },
    invoice: {
      INVOICE: "FAKTURA",
      contactDetailsSection: {
        billedTo: "Faktureret til",
      },
      invoiceDetailsSection: {
        invoiceNo: "Fakturanummer",
        issueDate: "Udgivelsesdato",
        dueDate: "Forventet fødselsdato",
      },
      invoicePayButton: {
        paid: "betalt",
        pay: "Betale",
      },
      invoiceItemsList: {
        itemName: "Varenavn",
        price: "Pris",
        qty: "Mængde",
      },
      orderSummary: {
        amountDue: "Beløb til betaling",
        taxes: "Skatter",
        discount: "Rabat",
        shipping: "Forsendelse",
      },
      amountPaid: "Beløb betalt",
      generatedOn: "Genereret den",
      generatingInvoicePDF: "Generering af faktura-pdf",
      generatingReceiptPDF: "Genererer kvitterings-PDF",
      error: "UPS, Den faktura, du leder efter, kan ikke findes!",
      receipt: {
        RECEIPT: "Kvittering",
        receiptNo: "Kvitteringsnummer",
        datePaid: "Dato Betalt",
        error: "UPS, Kvitancen, du leder efter, kan ikke findes!",
      },
      download: {
        clickHere: "Klik her",
        toDownloadPDF: "at downloade pdf",
      },
      clickHereToMakePaymentNow: "Klik her for at foretage betalingen nu",
      paymentProcessing: "Betalingsbehandling",
      PAID: "BETALT",
      termsAndNotes: "Betingelser og noter",
      total: "Total",
    },
    paymentLink: {
      error: "Hovsa, betalingslinket du leder efter kan ikke findes!",
      productDetails: "Produktdetaljer",
      validation: {
        firstName: "Fornavn påkrævet",
        lastName: "Efternavn påkrævet",
        address: "Adresse påkrævet",
        city: "By krævd by",
        state: "Statskrav",
        phoneRequried: "Telefonnummer påkrævet",
        phoneChars: "Telefonnummer kan kun indeholde tal",
        phoneCountryCode:
          "Vær venlig at sikre dig, at dit telefonnummer indeholder tegn i overensstemmelse med landekoden, inden du fortsætter",
        email: "E-mail påkrævet",
        validEmail: "Indtast venligst en gyldig emailadresse",
      },
      additionalSetupFee: "Engangsopsætningsgebyr",
      afterTrialText: "Efter din prøveperiode udløber, vil du blive opkrævet",
      afterTrialCancellationText: "Du kan altid afbestille inden da",
      subscriptionTextPrefix: "Ved at bekræfte dit abonnement tillader du",
      subscriptionTextSuffix:
        "at opkræve dig fremtidige betalinger i overensstemmelse med deres vilkår. Du kan altid opsige dit abonnement",
      firstName: "Fornavn",
      lastName: "Efternavn",
      email: "Email",
      phone: "telefon",
      address: "Adresse",
      addressLine1: "Første linje i adresse",
      addressLine2: "Adresse linje 2",
      city: "By",
      state: "stat",
      paymentSuccessful:
        "Betaling modtaget succesfuldt! Tak for at vælge vores tjenester. Din transaktion er bekræftet",
      thanksText: "Tak for din betaling",
      notification: {
        errorPayment: "Fejl ved betaling",
        orderSuccessful: "Bestillingen er gennemført succesfuldt",
        invalidCoupon: "Ugyldig kuponkode",
      },
      per: "per",
      then: "Så",
      free: "gratis",
      day: "dag",
      days: "dage",
      discount: "Rabat (kupon)",
      subtotal: "Delsum",
      subtotalAfterDiscount: "Delsum efter rabat",
      taxes: "Skatter",
      priceAfterTrial:
        "{currency}{price} / {interval} efter prøveperioden | {currency}{price} / {intervalCount} {interval} efter prøveperioden",
    },
    paymentMethod: {
        update: "Opdatering",
        upcomingInvoicePayment: "Forventet faktura betaling",
        updatePaymentMethod: "Opdater betalingsmetode",
        successTitle: "Betalingsmetoden blev opdateret med succes",
        successMessage: "Betalingsmetoden blev tilføjet til abonnementet",
        purchasedOn: "Købt den",
        on: "På",
        endingIn: "enden i"
    }
  },
};