import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/invoice/:invoiceId",
    name: "InvoicePreview",
    component: () => import("../pages/InvoicePreview.vue"),
  },
  {
    path: "/receipts/:receiptId",
    name: "ReceiptPreview",
    component: () => import("../pages/ReceiptPreview.vue"),
  },
  {
    path: "/payment-link/:paymentLinkId",
    name: "PaymentLinkPreview",
    component: () => import("../pages/PaymentLinkPreview.vue"),
  },
  {
    path: "/payment-method/update/:token",
    name: "PaymentMethodUpdatePreview",
    component: () => import("../pages/PaymentMethodUpdatePreview.vue"),
  },
  {
    path: "/razorpay/payment",
    name: "RazorpayPayment",
    component: () => import("../pages/RazorpayPayment.vue"),
  },
  {
    path: "/razorpay/marketplace",
    name: "RazorpayMarketplacePayment",
    component: () => import("../pages/RazorpayMarketPlacePage.vue"),
  },
  {
    path: "/razorpay/oauth/finish",
    name: "RazorpayOauthCodeAccess",
    component: () => import("../pages/RazorpayMarketPlaceOauthCode.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
