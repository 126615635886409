export default {
  nl: {
    common: {
      subtotal: "Subtotaal",
      cancel: "Annuleren",
      processing: "Verwerking",
      pay: "Betalen",
      error: "Er is iets misgegaan, probeer het later opnieuw!",
      code: "Code",
      status: "Status",
      day: "dag | dagen",
      week: "week",
      month: "maand",
      year: "jaar",
      minute: "minute",
      createdAt: "Aangemaakt op",
      apply: "Toepassen",
      remove: "Verwijderen",
      free: "gratis",
      noPaymentMethod: "Geen geldige betaalmethode gevonden",
    },
    invoice: {
      INVOICE: "FACTUUR",
      contactDetailsSection: {
        billedTo: "In rekening gebracht aan",
      },
      invoiceDetailsSection: {
        invoiceNo: "Factuur nr",
        issueDate: "Uitgiftedatum",
        dueDate: "Vervaldatum",
      },
      invoicePayButton: {
        paid: "betaald",
        pay: "Betalen",
      },
      invoiceItemsList: {
        itemName: "Itemnaam",
        price: "Prijs",
        qty: "Aantal",
      },
      orderSummary: {
        amountDue: "Bedrag verschuldigd",
        taxes: "Belastingen",
        discount: "Korting",
        shipping: "Verzending",
      },
      amountPaid: "Bedrag betaald",
      generatedOn: "Gegenereerd op",
      generatingInvoicePDF: "Het genereren van factuur pdf",
      generatingReceiptPDF: "Genereren van bon pdf",
      error: "OEPS, de factuur die u zoekt kan niet worden gevonden!",
      receipt: {
        RECEIPT: "bon",
        receiptNo: "Bonnummer",
        datePaid: "Datum betaald",
        error: "OEPS, Het bonnetje dat je zoekt kan niet worden gevonden!",
      },
      download: {
        clickHere: "Klik hier",
        toDownloadPDF: "PDF downloaden",
      },
      clickHereToMakePaymentNow: "Klik hier om nu de betaling te doen",
      paymentProcessing: "Betalingsverwerking",
      PAID: "BETAALD",
      termsAndNotes: "Voorwaarden en aantekeningen",
      total: "Totaal",
    },
    paymentLink: {
      error: "OEPS, de betalingslink die je zoekt kan niet worden gevonden!",
      productDetails: "Productdetails",
      validation: {
        firstName: "Voornaam vereist",
        lastName: "Achternaam is verplicht",
        address: "Adres vereist",
        city: "Stad vereist",
        state: "Wettelijk vereist",
        phoneRequried: "Telefoonnummer vereist",
        phoneChars: "Telefoonnummer kan alleen cijfers bevatten",
        phoneCountryCode:
          "Zorg ervoor dat uw telefoonnummer de juiste tekens bevat volgens het landnummer voordat u verder gaat",
        email: "E-mail vereist",
        validEmail: "Gelieve een geldig e-mailadres in te voeren",
      },
      additionalSetupFee: "Eenmalige opstartkosten",
      afterTrialText:
        "Na afloop van uw proefperiode wordt er kosten in rekening gebracht",
      afterTrialCancellationText:
        "Je kunt altijd annuleren voordat het zover is",
      subscriptionTextPrefix:
        "Door uw abonnement te bevestigen, geeft u toestemming",
      subscriptionTextSuffix:
        "Je te belasten voor toekomstige betalingen volgens hun voorwaarden. Je kunt altijd je abonnement annuleren",
      firstName: "Voornaam",
      lastName: "Achternaam",
      email: "E-mail",
      phone: "Telefoon",
      address: "Adres",
      addressLine1: "Adresregel 1",
      addressLine2: "Adresregel 2",
      city: "Stad",
      state: "Staat",
      paymentSuccessful:
        "Betaling succesvol ontvangen! Bedankt dat u voor onze diensten heeft gekozen. Uw transactie is bevestigd",
      thanksText: "Bedankt voor uw betaling",
      notification: {
        errorPayment: "Fout bij het maken van de betaling",
        orderSuccessful: "Bestelling succesvol geplaatst",
        invalidCoupon: "Ongeldige kortingscode",
      },
      per: "per",
      then: "Dan",
      free: "gratis",
      day: "dag",
      days: "dagen",
      discount: "Korting (coupon)",
      subtotal: "Subtotaal",
      subtotalAfterDiscount: "Subtotaal na korting",
      taxes: "Belastingen",
      priceAfterTrial:
        "{currency}{price} / {interval} na proefperiode | {currency}{price} / {intervalCount} {interval} na proefperiode",
    },
    paymentMethod: {
        update: "Bijwerken",
        upcomingInvoicePayment: "Aankomende factuurbetaling",
        updatePaymentMethod: "Bijwerken betalingsmethode",
        successTitle: "Betalingsmethode is succesvol bijgewerkt",
        successMessage: "De betaalmethode is toegevoegd aan het abonnement",
        purchasedOn: "Gekocht op",
        on: "Op",
        endingIn: "eindigend in"
    }
  },
};
